<template>
  <div class="s_task_issue_dian_fu_step_one">
    <h3 class="s-task-issue-step-title">第一步、选择商城</h3>
    <div class="s-task-issue-step-item s-task-issue-step-one-item-first">
      <el-radio-group v-model="form.platform_id" @change="shopTypeGroupChange">
        <el-radio v-for="(item, index) in platformList" :key="index" :label="item.id">{{item.title}}</el-radio>
      </el-radio-group>

      <div class="s-task-issue-step-one-item-first-store">
        <div class="s-task-issue-step-one-item-first-store-top g-padtop20 g-flex-align-center">
          <p class="s-task-issue-step-item-label">选择店铺:</p>
          <p class="s-task-issue-step-one-item-first-store-right g-padleft5">
            选择发布活动宝贝的掌柜店铺，若未绑定，请 <span class="g-pointer g-padright5 g-padleft5 g-tips-color" @click="$router.push({ path: '/moneystore/storelist'})"> 点击此处 </span>  进行绑定
          </p>
        </div>
        <div class="s-task-issue-step-one-item-first-store-bottom g-padtop20 s-task-issue-step-item-padding20">
          <el-select v-model="form.user_shop_id" placeholder="请选择" size="mini">
            <el-option v-for="item in storeList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </div>

      </div>
    </div>


    <h3 class="s-task-issue-step-title">第二步、选择类型</h3>
    <div class="s-task-issue-step-item s-task-issue-step-one-item-first">
      <el-radio-group v-model="form.platform_play_id" class="s-task-issue-step-item-group g-flex-column" @change="taskTypeGroupChange">
        <div class="s-task-issue-step-item-group-item g-padtop10" v-for="(item,index) in platformPlayList" :key="index">
          <div class="g-flex-align-center">
            <el-radio :label="item.id">{{item.title}}</el-radio>
            <p class="g-fsize14">{{item.remark}}</p>
          </div>
          <!-- 活动单类型  入口-->
          <div class="s-task-issue-step-item-group-item-type-list g-flex-align-center" v-show="form.platform_play_id === item.id && item.extend.length > 0">
            <p class="g-tips-color">选择活动单类型: </p>
            <el-radio-group v-model="form.entrance">
               <el-radio :label="item2" v-for="(item2,index2) in item.extend" :key="index2">{{item2}}</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-radio-group>
      
    </div>

    <h3 class="s-task-issue-step-title" v-if="fkModelQuanXuan == 1">第三步、选择本金返款模式</h3>
    <div class="s-task-issue-step-item s-task-issue-step-one-item-first" v-if="fkModelQuanXuan == 1">
      <el-radio-group v-model="form.fk_mode">
        <el-radio :label="item.val" v-for="(item, index) in fkModelList" :key="index">{{item.title}}</el-radio>
      </el-radio-group>
    </div>

  </div>
</template>

<script>
  export default {
    props: {
      fkModelQuanXuan: {
        type: Number,
        default() {
          return 0
        }
      },
      form: {
        type: Object,
        default() {
          return {
            platform_id: '',
            platform_play_id: 0,
            platform_play_type: 0, //任务类型是啥 0=未知；1=普通垫付；2=预售垫付；3=叠词回访；4=竞品流量劫持；5=高级引流；6=特别垫付；7=开团垫付；8=直播垫付；
            entrance: '',
            user_shop_id: '',
            fk_mode: 0
          }
        }
      },
      //platformList 商城列表   platformPlayList 任务类型
      platformList: {
        type: Array,
        default() {
          return [{ title: '', id: '', platformPlayList: []}]
        }
      },
      // 所有店铺列表
      allStoreList: {
        type: Array,
        default() {
          return [{ id: '', platform_id: '', title: ''}]
        }
      },

      // 平台返款模式
      fkModelList: {
        type: Array,
        default() {
          return []
        }
      },
    },
    data() {
      return {
        value: 0,
        activityTypeList: [
          { name: '淘口令', value: 1 },
          { name: '聚划算', value: 2 },
          { name: '淘抢购', value: 3 },
          { name: '淘金币', value: 4 },
          { name: '天天特价', value: 5 },
          { name: '其他', value: 5 }
        ]
      }
    },
    methods: {
      // 商城选择
      shopTypeGroupChange() {
        this.form.platform_play_id = 0
        this.form.user_shop_id = ''
        this.form.entrance = ''
      },

      // 任务类型变化时
      taskTypeGroupChange(val) {
        this.form.entrance = ''
        // 拿到选择的是哪个类型
        let target = this.platformPlayList.find(item => item.id === val)
        if(target) {
          this.form.platform_play_type = target.type
          console.log('platform_play_type', this.form.platform_play_type)
          console.log('platform_play_id', this.form.platform_play_id)
        }
      }
    },
    computed: {
      // 类型列表
      platformPlayList() {
        if(this.platformList.length === 1 || !this.form.platform_id) return []
        return this.platformList.find((item) => item.id === this.form.platform_id).platformPlayList
      },

      // 商店列表
      storeList() {
        if(this.allStoreList.length === 1 || !this.form.platform_id) return []
        let defult = [{ title: '请选择', id: '', platform_id: ''}]
        return defult.concat(this.allStoreList.filter((item) => item.platform_id === this.form.platform_id))
      }
    }
  }
</script>

<style lang="scss">
.s_task_issue_dian_fu_step_one {
  .s-task-issue-step-item {
    background: $white;
    padding: 20px;
    border-radius: 8px;
    .s-task-issue-step-item-group {
      width: 100%;
      font-size: 14px;
      .s-task-issue-step-item-group-item {
        .el-radio {
          margin-right: 10px;
        }
        .s-task-issue-step-item-group-item-type-list {
          padding-top: 20px;
          padding-bottom: 10px;
          font-weight: bold;
          .el-radio-group {
            padding-left: 20px;
          }
        }
      }
    }
  }
}
</style>